<template>
    <div class="row">
    <div class="w-100">
      <div class="btn-add-application d-flex justify-content-end mr-0">
            <button v-if="!is_bod && !is_tbp && !is_leader" style="background-color: #472f92;" @click="dialogRequestApplications = true" class="btn btn-primary mr-3 shadow-none border-0">
            <i class="fa fa-plus mr-1"></i> Thêm mới</button> 
        </div>
      <div id="panel-1" class="panel mt-1">
        <div id="list-propse-application" class="panel-container show">
            <div class="panel-content">
                <div class="d-flex ml-3 style-color">
                    <div class="row header">
                        <div class="mr-2 mt-1">
                            <div>
                                <label for=""><b>Tên/Mã nhân sự</b></label><br>
                                <el-input class="w-100" placeholder="Nhập mã/tên nhân sự" v-model="query.keyword" clearable></el-input>
                            </div>
                        </div>
                        <div class="mr-2 mt-1">
                            <div class="time-create">
                                <label for=""><b>Thời gian tạo</b></label><br>
                                <el-date-picker
                                    class="res-head w-100"
                                    v-model="query.month"
                                    type="month"
                                    value-format="MM-yyyy"
                                    placeholder="Chọn tháng">
                                </el-date-picker>
                            </div>
                        </div>
                        <div v-if="!is_bod" class="mr-2 mt-1">
                            <div class="time-create">
                                <label for=""><b>Danh mục</b></label><br>
                                <div class="form-group w-100">
                                    <el-select v-model="query.category" class="res-head w-100" placeholder="Chọn danh mục" collapse-tags clearable>
                                        <el-option label="Nghỉ phép" :value="0"> </el-option>
                                        <el-option label="Tăng ca" :value="1"> </el-option>
                                        <el-option label="Xin đổi ca làm việc" :value="2"> </el-option>
                                        <el-option label="Bổ sung giờ làm việc ngoài văn phòng" :value="3"> </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="mr-2 mt-1">
                            <div>
                                <div v-if="is_leader || is_bod" class="form-group">
                                    <label style="display: block" v-if="is_leader" for=""><b>Bộ phận</b></label>
                                    <label style="display: block" v-if="is_bod" for=""><b>Phòng ban</b></label>
                                    <el-select class="w-100" clearable v-model="query.department" placeholder="Chọn phòng ban">
                                        <el-option
                                        v-for="item in allDepartment"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="mr-2 mt-1">
                            <div>
                                <label for=""><b>Trạng thái</b></label><br>
                                <div class="form-group">
                                    <el-select v-model="query.status" class="res-head w-100" placeholder="Chọn trạng thái" collapse-tags clearable>
                                        <el-option label="Đang chờ duyệt" :value="0"> </el-option>
                                        <el-option label="Đã duyệt" :value="1"> </el-option>
                                        <el-option label="Đã từ chối" :value="2"> </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="mr-2 mt-1">
                            <div>
                                <div class="form-group" @click="search">
                                <button :disabled='loadingSearch' style="background-color: #472f92;" class="btn btn-primary mr-2 mt-4">
                                    <i v-bind:class="[loadingSearch ? 'el-icon-loading' : 'el-icon-search']" class="mr-1 text-white"></i>Tìm kiếm
                                </button>
                            </div>
                        </div>
                            
                        </div>
                    </div>
               </div>
            <div class="mt-5 example-preview table-responsive">
            <table class="table table-vertical-center  table-bordered">
            <thead>
              <tr style="background-color: #F8F8F8;">
                <th>STT</th>
                <th>Người đề xuất</th>
                <th v-if="!is_bod" >Danh mục</th>
                <th v-if="is_leader || is_tbp">Bộ phận</th>
                <th v-if="is_bod">Phòng ban</th>
                <th>Lý do</th>
                <th>Trạng thái</th>
                <th v-if="!is_bod" >Thời gian tạo</th>
                <th>Thao tác</th>
              </tr>
            </thead>
            <tbody v-if="loadingTable">
              <tr role="row" class="b-table-busy-slot">
                <td colspan="16" role="cell" class="">
                  <div class="text-danger my-2">
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody  v-else-if ="ListData?.length > 0">
              <tr v-for="(item, index) in ListData">
                <td>{{ index + 1 }}</td>
                <td>{{ (item?.user?.id + 100000) + ' - ' + item?.user?.name }}</td>
                <td v-if="!is_bod" class="style-color">
                    <div v-if="item.category == 0">Nghỉ phép</div>
                    <div v-if="item.category == 1">Tăng ca</div>
                    <div v-if="item.category == 3">Bổ sung giờ làm việc</div>
                    <div v-if="item.category == 2">Xin đổi ca làm việc</div>
                </td>
                <td>{{ item.parent_department ? item.parent_department : '' }}</td>

                <td class="style-color">{{ checkLength(item.reason, 30) }}</td>
                <td class="style-color">
                    <div v-if="item.status == 0">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: #C0C0C0"></div>
                        <span>Đang chờ duyệt</span>
                    </div>
                    <div v-if="item.status == 1">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: #009821;"></div>
                            <span>Đã duyệt</span>
                    </div>
                    <div v-if="item.status == 2">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: red"></div>
                        <span>Đã từ chối</span>
                    </div>
                </td>
                <td v-if="!is_bod" class="style-color">{{ item.created_at | formatDateTime }}</td>
                <td >
                    <a style="margin-left: 10px;" title="Xem chi tiết" @click="showDetail(item)" href="javascript:"
                        class="btn btn-sm ml-1 btn-icon btn-outline-success">
                        <i class="fas fa-eye"></i>
                    </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
                    <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{ totoalData }}</b></div>
               </div>
                    <div id="paginate" class="edu-paginate mx-auto d-flex justify-content-center">
                            <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                                :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                                :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                                :next-class="'page-link'" :page-class="'page-item'">
                            </paginate>
                    </div>
            </div>
             <!-- dialog Show chi tiết yêu cầu -->
            <el-dialog id="dialogShowDetail"  :title="`Xem chi tiết đơn đề xuất: ${ titleDetail }`" :visible.sync="dialogShowDetail">
                <div class="container style-color">
                    <div class="info-user pt-3 border-top">
                            <div class="row">
                                <div class="col-xxl-12 col-md-6 mt-3">
                                    <label class="mt-1" for=""><b>Mã nhân sự</b></label>
                                    <el-input disabled :value="infoDetail?.user?.id + 100000"></el-input>
                                </div>
                                <div class="col-xxl-12 col-md-6 mt-3">
                                    <label for=""><b>Họ và tên</b></label>
                                    <el-input disabled :value="infoDetail?.user?.name"></el-input>
                                </div>
                                <div class="col-xxl-12 col-md-6 mt-3">
                                    <label for=""><b>Vị trí</b></label>
                                    <el-input disabled :value="infoDetail?.user?.profile?.account_type?.name"></el-input>
                                </div>
                                <div class="col-xxl-12 col-md-6 mt-3">
                                    <label for=""><b>Phòng ban </b></label>
                                    <el-input clearable disabled :value="infoUser?.department?.department?.name"></el-input>
                                </div>
                            </div>
                    </div>

                    <!-- nếu là danh mục nghỉ phép -->
                    <div v-if="infoDetail?.category == 0" class="info-request mt-3">
                        <div class="row">
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Tổng số công nghỉ phép</b></label>
                                <el-input class="no-spinner" disabled :value="infoDetail?.general_leave + ' Công'"></el-input>
                            </div>
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Ngày bắt đầu nghỉ phép</b></label>
                                <el-input disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Buổi bắt đầu nghỉ phép</b></label>
                                <el-input v-if="infoDetail?.start_shift_off == 1" disabled value="Ca sáng"></el-input>
                                <el-input v-if="infoDetail?.start_shift_off == 2" disabled value="Ca chiều"></el-input>
                                <el-input  v-if="infoDetail?.start_shift_off == 3" disabled value="Ca tối"></el-input>
                            </div>
                            <div class="col-xxl-12 col-md-6 mt-2">
                                <label for=""><b>Ngày kết thúc nghỉ phép</b></label>
                                <el-input disabled :value="infoDetail?.end_date | formatDate"></el-input>
                            </div>
                        </div>
                        <div class="mt-3">
                                    <div><b>Lý do nghỉ phép</b><span class="text-danger">*</span></div>
                                    <textarea :value="infoDetail?.reason" :maxlength="500" style="width: 100%;"  class=" mt-1 p-2" rows="4"></textarea>
                        </div>
                        <div class="col-md-12 d-flex p-0">
                            <div class="col-xl-6 mt-3 pl-0">
                                <div><b>Số điện thoại liên hệ khi cần</b><span class="text-danger">*</span></div>
                                <el-input class="mt-1 " disabled :value="infoDetail?.phone ? infoDetail?.phone : infoUser?.phone"></el-input>
                            </div>
                            <div class="col-xl-6 mt-3 pl-0">
                                <div><b>Loại nghỉ phép</b><span class="text-danger">*</span></div>
                                <div class="mt-3">
                                    <el-radio v-model="infoDetail.type_leave" :label="0" disabled>Nghỉ dùng phép</el-radio>
                                    <el-radio v-model="infoDetail.type_leave" :label="1" disabled>Nghỉ không lương</el-radio>
                                </div>
                            </div>
                        </div>

                    </div>
                     <!-- nếu là danh mục tăng ca -->
                    <div v-if="infoDetail?.category == 1" class="info-request mt-3">
                        <div class="d-flex line-cao res-input row">
                            <div class="col-xl-4">
                                <div><b>Ngày tăng ca</b></div>
                                <el-input class="w-100" disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Giờ bắt đầu</b></span></div>
                                <el-input class="w-100"  disabled :value="infoDetail?.time_checkin"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Giờ kết thúc</b></span></div>
                                <el-input class="w-100"  disabled :value="infoDetail?.time_checkout"></el-input>
                            </div>
                        </div>
                        <div class=" row  d-flex res-input line-cao mt-3">
                            <div class="col-xl-4">
                                <div><b>Giờ bắt đầu chấm công thực tế</b></div>
                                <el-input  disabled="true" :value="fingerprint.fingerprint_start | formatDateTime"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><b>Giờ kết thúc chấm công thực tế</b></div>
                                <el-input  disabled="true" :value="fingerprint.fingerprint_end | formatDateTime"></el-input>
                            </div>
                        </div>
                        <div class="line-cao mt-3 row">
                            <div class="col-xl-12">
                                <div><b>Lý do tăng ca</b></div>
                                <textarea :maxlength="500" :value="infoDetail?.reason" class="mt-1 p-2 rounded-3" placeholder="Nhập lý do tăng ca"  rows="4" style="width: 100%;"></textarea>
                            </div>
                        </div>
                        <div class="line-cao mt-3 row">
                            <div class="col-xl-12">
                                <div><b>Kết quả tăng ca</b></div>
                                <textarea :maxlength="500" :value="infoDetail?.overtime_results" class="mt-1 p-2 rounded-3" placeholder="Kết quả tăng ca"  rows="4" style="width: 100%;"></textarea>
                            </div>
                        </div>
                    </div>
                     <!-- nếu là danh mục Bổ sung giờ làm việc ngoài văn phòng -->
                    <div v-if="infoDetail?.category == 3" class="info-request mt-3">
                            <div class="d-flex line-cao res-input row">
                                    <div class="col-xl-4">
                                        <div><b>Ngày cần bổ sung</b></div>
                                        <el-input disabled :value="infoDetail?.start_date | formatDate"></el-input>
                                    </div>
                                    <div class="col-xl-4">
                                        <div><span><b>Thời gian bắt đầu</b></span></div>
                                        <el-input  disabled :value="infoDetail?.time_checkin"></el-input>
                                    </div>
                                    <div class="col-xl-4">
                                        <div><span><b>Giờ kết thúc</b></span></div>
                                        <el-input  disabled :value="infoDetail?.time_checkout"></el-input>
                                    </div>
                                </div>
                        <div class="line-cao mt-3">
                            <div><b>Lý do bổ sung</b></div>
                            <textarea :maxlength="500" :value="infoDetail?.reason" class="mt-1 p-2 rounded-3" placeholder="Nhập lý do bổ sung"  rows="4" style="width: 100%;"></textarea>
                        </div>
                    </div>
                     <!-- nếu là danh mục xin đổi ca -->
                    <div v-if="infoDetail?.category == 2" class="info-request mt-3">
                        <div class="d-flex res-input  line-cao">
                            <div class="col-xl-4 mt-3">
                                <div><b>Ngày đổi ca</b></div>
                                <el-input disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xl-4 mt-3">
                                <div><b>Ca hiện tại</b></div>
                                <el-input disabled :value="changeCurrentWorking(infoDetail?.current_working_hours)"></el-input>
                            </div>
                            <div class="col-xl-4 mt-3">
                                <div><b>Ca thay đổi</b></div>
                                <el-input disabled :value="changeCurrentWorking(infoDetail?.current_working_change)"></el-input>
                            </div>
                        </div>
                        <div class="mr-4 mt-3">
                                <div>
                                    <div><b>Lý do đổi ca</b></div>
                                    <textarea :value="infoDetail?.reason" :maxlength="500" style="width: 100%;"  class=" mt-1 p-2 " rows="4"></textarea>
                                </div>
                        </div>
                    </div>
                    <!-- box show detail bottom -->
                    <div class="info-request-detail line-cao d-flex row">
                        <div class="col-xl-6 mt-3">
                                <div>
                                    <div><b>Trạng thái đơn</b></div>
                                    <el-input class="w-100" v-if="infoDetail?.status == 0 " disabled value="Đang chờ duyệt"></el-input>
                                    <el-input class="w-100" v-if="infoDetail?.status == 1 " disabled value="Đã duyệt"></el-input>
                                    <el-input class="w-100" v-if="infoDetail?.status == 2 " disabled value="Đã từ chối"></el-input>
                                </div>
                        </div>
                        <div class="col-xl-6 mt-3" v-if="infoDetail?.user_handler && infoDetail?.status == 1">
                            <div>
                                <div><b>Người duyệt</b></div>
                                <el-input class="w-100" disabled :value="infoDetail?.user_handler?.name"></el-input>
                            </div>
                        </div>
                        <div class=" col-xl-6 mt-3" v-if="infoDetail?.user_handler && infoDetail?.status == 2">
                            <div>
                                 <!-- NGƯỜI TỪ CHỐI -->
                                <div><b>Người từ chối</b></div>
                                <el-input class="w-100" disabled :value="infoDetail?.user_handler?.name"></el-input>
                            </div>
                        </div>
                        <div class=" col-xl-6 mt-3" v-if="infoDetail?.category == 1 && infoDetail?.status == 1">
                            <div>
                                <!-- NGƯỜI TỪ CHỐI -->
                                <div><b>Loại tăng ca</b></div>
                                <el-input class="w-100" :value="infoDetail?.type_arise ? 'Phát sinh công việc' : 'Phát sinh giờ làm'" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div v-if="infoDetail?.user_handler && infoDetail?.status == 2">
                            <!-- LÝ DO TỪ CHỐI -->
                            <div class="w-100 mt-3">
                                <div>
                                    <div><b>Lý do từ chối</b></div>
                                    <textarea :maxlength="500" placeholder="Không có lý do từ chối" :value="infoDetail?.reason_for_refusal" class="mt-1 p-2 rounded-3 w-100"  rows="4" style="width: 100%;"></textarea>
                                </div>
                            </div>
                    </div>
                    <div v-if="infoDetail.status == 0 && !is_bod" class="d-flex justify-content-end mt-4">
                        <button @click="changedilogRefuse('Refuse')" class="btn btn-danger rounded mr-3 shadow-none border-0">Từ chối</button>
                        <button v-if="infoDetail.category != 1" @click="changedilogRefuse('Accept')" class="btn btn-success rounded shadow-none border-0">Duyệt</button>
                        <button v-else @click="createOverTime" class="btn btn-success rounded shadow-none border-0">Duyệt</button>
                    </div>
                </div>
            </el-dialog>

            <el-dialog  style="z-index: 10000; margin-top: 20vh" :title="`Xoá đơn xin ${ titleDetail }`" :visible.sync="dialogConfirmDeleteRequest">
                    <div class="pt-5 mb-5 border-top">
                        <h4>Bạn có chắc chắn muốn xóa đơn xin nghỉ phép ngày {{ infoDetail.start_date }} không?</h4>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div><button  type="" @click="dialogConfirmDeleteRequest = false" class="rounded mr-3 btn-refuse  shadow-none border-0">Huỷ bỏ</button></div>
                        <div><button  @click="actionDelete(infoDetail?.id)" style="background-color: #472f92 ;" class="rounded mr-3 btn-accept shadow-none border-0">Xác nhận</button></div>
                    </div>
            </el-dialog>

             <!-- dialog chấp nhận yêu cầu nghỉ phép -->
            <el-dialog id="dialog-request-applications" :title="`Duyệt đơn ${ titleDetail }`" :visible.sync="dialogConfirmRequest">
                <div class="border-top border-dark pt-4" style="word-break: break-word">
                    <div  v-if="infoDetail?.category == 0">
                        <p>Teamlead-BOD xét duyệt đơn xin nghỉ phép của nhân sự vui lòng đảm bảo công việc của nhân sự nghỉ phép hoặc sắp xếp nhân sự đảm nhiệm công việc đúng tiến độ!</p> <br>
                        <p>Bạn có chắc chắn muốn duyệt đơn xin nghỉ phép ngày {{ infoDetail.start_date | formatDate }} không?</p>
                    </div>
                    <div v-else>
                        <span>
                           Bạn có chắc chắn muốn duyệt đơn {{ titleDetail }} của {{ infoDetail?.user?.name }} ngày {{ infoDetail.start_date | formatDate }} không?
                        </span>
                    </div>
                </div>
                <div class="box-bottom-decide  d-flex justify-content-end mt-4">
                    <div><button @click="dialogConfirmRequest = false" class="rounded mr-3 btn-refuse  shadow-none border-0">Huỷ bỏ</button></div>
                    <div><button @click="handleRequest(infoDetail?.id, true)" style="background-color: #472f92 ;" class="rounded mr-3 btn-accept shadow-none border-0">Xác nhận</button></div>
                </div>
            </el-dialog>

             <!-- dialog từ chối yêu cầu nghỉ phép -->
             <el-dialog width="40" id="box-tu-choi" title="Nhập lý do từ chối đơn" :visible.sync="dialogRefusetRequest">
                    <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="Nhập lý do từ chối"
                    v-model="query.reason_for_refusal">
                    </el-input>
                <div class="box-bottom-decide d-flex justify-content-end mt-3">
                    <div><button @click="dialogRefusetRequest = false" class="rounded mr-3 btn-refuse">Huỷ bỏ</button></div>
                    <div><button @click="handleRequest(infoDetail?.id, false)" class="rounded mr-3 btn-accept">Xác nhận</button></div>
                </div>
            </el-dialog>

            <!-- dialog tạo yêu cầu nghỉ phép -->
            <el-dialog id="dialog-request-applications" :title="`Thêm mới đề xuất: ${ titleDetail}`" :visible.sync="dialogRequestApplications">
            <div class="requestRest ml-3 style-color">
                <div class=" d-flex type-applications">
                    <div class="mr-2 mt-2"><p><b>Bạn đang sử dụng mẫu:</b></p></div>
                    <div class="d-flex">
                        <el-select class="w-100" @change="changeCategory" id="chooseType" type="danger" v-model="query.id_type" placeholder="Mẫu đơn">
                            <el-option
                            class="w-100"
                                v-for="item in typeApplications"
                                :key="item.id"
                                :label="item.type"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div> 
                </div>
                <div class="info-user mt-3">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-3 col-md-6 mt-2">
                                <label for=""><b>Mã nhân sự</b></label>
                                <el-input disabled :value="infoUser?.id + 100000"></el-input>
                            </div>
                            <div class="col-xxl-12 col-xl-3 col-md-6 mt-2">
                                <label for=""><b>Họ và tên</b></label>
                                <el-input disabled :value="infoUser?.name"></el-input>
                            </div>
                            <div class="col-xxl-12 col-xl-3 col-md-6 mt-2">
                                <label for=""><b>Vị trí</b></label>
                                <el-input disabled :value="infoUser?.profile?.account_type?.name"></el-input>
                            </div>
                            <div class="col-xxl-12 col-xl-3 col-md-6 mt-2">
                                <label for=""><b>Phòng ban </b></label>
                                <el-input disabled :value="infoUser?.department?.department?.name"></el-input>
                            </div>
                        </div>
                </div>
                <div>
                    <div class="mt-3" v-if="query.id_type == 0 && query.id_type !== ''">
                            <div class="row">
                                <div class="col-xl-3 col-md-6 mt-2">
                                    <div><span><b>Tổng số công nghỉ phép<span class="text-danger">*</span></b></span></div>
                                    <el-input  class="res-head hideen-arrow-type-number w-100" @change="checkGeneralLeave"  v-model="query.general_leave" placeholder="Tổng số công nghỉ phép"></el-input>
                                </div>
                                <div class="col-xl-3 col-md-6 mt-2">
                                    <div><span><b>Ngày bắt đầu nghỉ phép<span class="text-danger">*</span></b></span></div>
                                    <div>
                                        <el-date-picker
                                            class="w-100"
                                            @change="checkDate"
                                            v-model="query.start_date"
                                            type="date"
                                            format-value="dd-mm-yyyy"
                                            placeholder="Chọn ngày bắt đầu nghỉ phép">
                                        </el-date-picker>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6 mt-2">
                                    <div><span><b>Buổi bắt đầu nghỉ phép<span class="text-danger">*</span></b></span></div>
                                    <div>
                                        <el-select v-model="query.start_shift_off" class="res-head w-100" placeholder="Chọn ca bắt đầu nghỉ" collapse-tags clearable>
                                            <el-option label="Ca sáng" :value="1"> </el-option>
                                            <el-option label="Ca chiều" :value="2"> </el-option>
                                            <el-option label="Ca tối" :value="3"> </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6 mt-2">
                                    <div><span><b>Ngày kết thúc nghỉ phép<span class="text-danger">*</span></b></span></div>
                                    <div>
                                        <el-date-picker
                                            class="w-100"
                                            @change="checkDate"
                                            v-model="query.end_date"
                                            type="date"
                                            placeholder="Chọn ngày kết thúc nghỉ phép">
                                        </el-date-picker>
                                    </div>
                                </div>
                            </div>
                        <div class="line-cao res-input">
                            <div>
                                <div><span><b>Lý do nghỉ phép<span class="text-danger">*</span></b></span></div>
                                <textarea :maxlength="500" v-model="query.reason" class="mt-1 p-2 rounded-3" placeholder="Nhập lý do nghỉ phép"  rows="4" style="width: 100%;"></textarea>
                            </div>
                        </div>
                        <div class="line-cao mt-3 w-100 d-flex">
                            <div class="col-md-3">
                                <div>
                                <span>
                                    <b>Số điện thoại liên hệ khi cần<span class="text-danger">*</span></b></span>
                                </div>
                                <div class="res-phone-contact" @click="phoneFocus" @focusout="phoneContact" v-if="!editPhoneNumber">
                                    <el-input :value="infoUser?.phone"></el-input>
                                </div>
                                <div class="res-phone-contact" @click="phoneFocus" @focusout="phoneContact" v-else>
                                    <el-input class="phone-number w-100 phone_contact hideen-arrow-type-number" :cols="20" style="width: 30%;" placeholder="Nhập số điện thoại liên hệ khi cần" v-model="query.phone" clearable>
                                    </el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" v-if="query.id_type ==  1">
                        <div class="d-flex line-cao res-input">
                            <div>
                                <div><b>Ngày Tăng ca</b><span class="text-danger">*</span></div>
                                    <div>
                                        <div class="search-end-time mr-2">
                                            <div class="block">
                                                <el-date-picker
                                                    class="w-100"
                                                    v-model="query.start_date"
                                                    format="dd/mm/yyyysss"
                                                    type="date"
                                                    placeholder="Chọn ngày"
                                                    clearable>
                                                </el-date-picker>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div>
                                <div><span><b>Giờ bắt đầu</b></span></div>
                                    <div class="mr-2">
                                        <el-time-select
                                            class="w-100"
                                            v-model="query.time_checkin"
                                            :picker-options="{
                                                start: '08:00',
                                                step: '00:30',
                                               end: '24:00'
                                            }"
                                            placeholder="Chọn giờ"
                                            clearable>
                                            </el-time-select>
                                </div>
                            </div>
                            <div class="mr-2">
                                <div><span><b>Giờ kết thúc</b></span></div>
                                    <el-time-select
                                            class="w-100"
                                            v-model="query.time_checkout"
                                            :picker-options="{
                                                start: '08:00',
                                                step: '00:30',
                                               end: '24:00'
                                            }"
                                            @change="changeTimeCheckOut"
                                            placeholder="Chọn giờ"
                                            clearable>
                                    </el-time-select>
                            </div>
                        </div>
                        <div class="line-cao mt-3">
                            <div>
                                <div><b>Lý do tăng ca</b><span class="text-danger">*</span></div>
                                <textarea :maxlength="500" v-model="query.reason" class="mt-1 p-2 rounded-3" placeholder="Nhập lý do tăng ca"  rows="4" style="width: 100%;"></textarea>
                            </div>
                        </div>
                        <div class="line-cao mt-3">
                            <div>
                                <div><b>Kết quả tăng ca</b><span class="text-danger">*</span></div>
                                <textarea :maxlength="500" v-model="query.overtime_results" class="mt-1 p-2 rounded-3" placeholder="Nhập lý do tăng ca"  rows="4" style="width: 100%;"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" v-if="query.id_type ==  3">
                        <div class="d-flex line-cao res-input">
                            <div class="mr-2">
                                <div><b>Ngày cần bổ sung</b><span class="text-danger">*</span></div>
                                    <div>
                                        <div class="search-end-time">
                                            <div class="block">
                                                <el-date-picker
                                                class="w-100"
                                                v-model="query.start_date"
                                                type="date"
                                                placeholder="Chọn ngày"
                                                clearable>
                                                </el-date-picker>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="mr-2">
                                <div><b>Thời gian bắt đầu</b><span class="text-danger">*</span></div>
                                    <div>
                                        <el-time-select
                                            class="w-100"
                                            v-model="query.time_checkin"
                                            :picker-options="{
                                                start: '08:00',
                                                step: '00:30',
                                                end: '24:00'
                                            }"
                                            placeholder="Chọn giờ"
                                            clearable>
                                            </el-time-select>
                                    </div>
                            </div>
                            <div class="mr-2">
                                <div><b>Thời gian kết thúc</b><span class="text-danger"> *</span></div>
                                    <el-time-select
                                            class="w-100"
                                            v-model="query.time_checkout"
                                            :picker-options="{
                                                start: '08:00',
                                                step: '00:30',
                                                end: '24:00'
                                            }"
                                            @change="changeTimeCheckOut"
                                            placeholder="Chọn giờ"
                                            clearable>
                                    </el-time-select>
                            </div>
                        </div>
                            <div class=" mr-4 mt-3">
                                <div>
                                    <div><b>Lý do Bổ sung</b><span class="text-danger">*</span></div>
                                    <textarea :maxlength="500" style="width: 100%;" v-model="query.reason" class=" mt-1 p-2 rounded-3" placeholder="Nhập lý do bổ sung"  rows="4"></textarea>
                                </div>
                            </div>
                    </div>
                    <div class="mt-3" v-if="query.id_type ==  2">
                        <div class="d-flex line-cao res-input">
                            <div class="mr-2">
                                    <div><span><b>Ngày đổi ca<span class="text-danger">*</span></b></span></div>
                                    <div class="mt-1">
                                            <div class="block">
                                                <el-date-picker
                                                class="w-100"
                                                v-model="query.start_date"
                                                type="date"
                                                placeholder="Chọn ngày"
                                                clearable>
                                                </el-date-picker>
                                            </div>
                                    </div>
                            </div>
                            <div class="mr-2">
                                <div><span><b>Ca hiện tại<span class="text-danger">*</span></b></span></div>
                                <div class="mt-1">
                                    <el-select @change="checkcurrentWorkingChange" class="w-100" v-model="query.current_working_hours" placeholder="Nhập ca thay đổi">
                                        <el-option
                                            v-for="item in workTime"
                                            :key="item.value"
                                            :label="item.ca"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
    
                            <div class="mr-2">
                                <div><span><b>Ca thay đổi<span class="text-danger">*</span></b></span></div>
                                <div class="mt-1">
                                    <el-select @change="checkcurrentWorkingChange" class="w-100" v-model="query.current_working_change" placeholder="Nhập ca thay đổi">
                                        <el-option
                                            v-for="item in workTime"
                                            :key="item.value"
                                            :label="item.ca"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
    
                        <div class="line-cao">
                            <div>
                                <div><b>Lý do đổi ca</b><span class="text-danger">*</span></div>
                                <textarea :maxlength="500" style="width: 100%;" v-model="query.reason" class="mt-1 p-2 rounded-3" placeholder="Nhập lý do đổi ca"  rows="4" ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="query.id_type || query.id_type !== ''" class="d-flex justify-content-end mt-4"><el-button @click="createPropose" class="fw-bolder" :disabled="loadingCreateRequest" v-loading="loadingCreateRequest" style="color:black; font-weight: 800;" type="warning" icon="el-icon-tickets">Tạo yêu cầu</el-button></div>
            </div>
            </el-dialog>
            <el-dialog
                title="Duyệt đơn xin tăng ca"
                :visible.sync="dialogConfirm"
                width="40%"
                :before-close="handleClose">
                <span>Bạn vui lòng chọn loại tăng ca để xác nhận duyệt đơn của nhân sự.</span>
                <div class="d-flex mt-4">
                    <div class="col-md-4">
                        <h4>Loại tăng ca: <span class="text-danger">*</span></h4>
                    </div>
                    <div>
                        <el-radio v-model="query.type_arise" :label="0" >Phát sinh giờ làm</el-radio>
                        <el-radio v-model="query.type_arise" :label="1">Phát sinh công việc</el-radio>
                    </div>
                </div>
                <div class="row mt-4" v-if="infoDetail?.category == 1">
                    <ul class="col-xl-12">Chú thích:
                        <li class="ml-6"> Phát sinh giờ làm: Công việc trong KRA nhưng do tính chất công việc phải làm thêm ngoài giờ hành chính.</li>
                        <li class="ml-6">Phát sinh công việc: Công việc nằm ngoài: KRA/ MTCV/ KRA công việc khác.</li>
                    </ul>
                </div>
                <div class="col-md-12">
                    <span class="text-danger">{{message_type_arise}}</span>
                </div>
                <span slot="footer" class="dialog-footer d-flex justify-content-end">
                    <div><button @click="dialogConfirm = false" class="rounded mr-3 btn-refuse  shadow-none border-0">Huỷ bỏ</button></div>
                    <div><button @click="confirmResultOverTime" style="background-color: #472f92 ;" class="rounded mr-3 btn-accept shadow-none border-0">Xác nhận</button></div>
                </span>
            </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {INFORMATION_MECHANISM} from "@/core/services/store/service.module";
import {GET_All_LIST_BOD, SHOW_DETAIL_APPLICATION , DELETE_USER_REQUEST, CREATE_APPLICATION_REQUEST, DECIDED_APPLITICATION} from "@/core/services/store/user/users.module";
import {USER_PROFILE, GET_DEPARTMENT} from "@/core/services/store/profile.module";
import moment from "moment-timezone";
export default {
    name: "list-propose-lead-bod",
    components: {
  },
    data() {
        return {
            page: 1,
            last_page: 1,
            query: 
            {
                current_working_change: '',
                current_working_hours: '',
                start_shift_off: '',
                general_leave: '',
                month: '',
                id_type: 0,
                category: '',
                start_date: '',
                end_date: '',
                phone: '',
                time_checkin: '',
                time_checkout: '',
                date_range: [],
                reason: '',
                overtime_results: '',
                reason_for_refusal: '',
                department: '',
                type_arise: 0,

            },
            fingerprint: 
            {
                fingerprint_start: '',
                fingerprint_end: '',
            },
            ListData: [],
            infoDetail: [],
            infoUser: [],
            allDepartment: [],
            titleDetail: 'Nghỉ phép',
            timeNow: new Date(),
            id_delete: '', 
            totoalData: '',
            error: 
            {
                checkLengthPhone: false,
                timecheckRest: false,
                general_leave: false,
                dateRest: false,
                checkphone: false

            },
            dialogRequestApplications: false,
            dialogShowDetail: false,
            dialogConfirmDeleteRequest: false,
            TimeSearch: '',
            loadingTable: false,
            loadingCreateRequest: false,
            dialogRefusetRequest: false,
            dialogConfirmRequest: false,
            editPhoneNumber: false,
            loadingSearch: false,
            is_bod: false,
            is_tbp: false,
            is_leader: false,
            typeApplications: 
            [
                {id: 0, type: 'Nghỉ phép'},
                {id: 1, type: 'Tăng ca'},
                {id: 2, type: 'Xin đổi ca làm việc'},
                {id: 3, type: 'Bổ sung giờ làm việc ngoài văn phòng'},
            ],
            phong_ban_xu_ly_define: {
                1: 'TTHT',
                2: 'Tài chính',
                3: 'Nhân sự',
                4: 'SPNN',
                5: 'CNTT',
                6: 'Đối tác',
                7: 'STVT',
                8: 'Đào tạo',
                9: 'Brand',
                10: 'Digital',
                11: 'Marketing',
                12: 'R&D',
                13: 'Pháp lý',
                14: 'QLHV',
                15: 'CSVC',
            },
            workTime: [
                { value: 1 , ca: 'Sáng-chiều' },
                { value: 2 , ca: 'Chiều-tối' },
                { value: 3 , ca: 'Sáng-tối' },
            ],
            phong_ban_xu_lys: [
                {id:1, name: 'TTHT'},
                {id:2, name: 'Tài chính'},
                {id:3, name: 'Nhân sự'},
                {id:4, name: 'SPNN'},
                {id:5, name: 'CNTT'},
                {id:6, name: 'Đối tác'},
                {id:7, name: 'STVT'},
                {id:8, name: 'Đào tạo'},
                {id:9, name: 'Brand'},
                {id:10, name: 'Digital'},
                {id:11, name: 'Marketing'},
                {id:12, name: 'R&D'},
                {id:13, name: 'Pháp lý'},
                {id:14, name: 'QLHV'},
                {id:15, name: 'CSVC'},
            ],
            dialogConfirm: false,
            message_type_arise: ''
        }
    },

    computed: {
    },
    mounted() {
        this.getInfoUser();
        this.search();
        this.getDepartments();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Đề xuất", icon: 'fas fa-list'},
            {title: "Danh sách duyệt đề xuất", icon: 'fas fa-list'}
        ]);
        this.queryServer();
    },

    watch: {
        
    },

    methods: {
        changeCategory() {
            let data = this.query;
            data.current_working_change = ''; 
            data.current_working_hours = ''; 
            data.start_shift_off = ''; 
            data.general_leave = ''; 
            data.month = ''; 
            data.start_date = ''; 
            data.end_date = ''; 
            data.reason = ''; 
            data.phone = ''; 
            data.time_checkin = ''; 
            data.time_checkout = ''; 
            data.date_range = [];
            switch (data.id_type) {
                case 0:
                    var result = 'Nghỉ phép'
                break;
                case 1:
                    var result = 'Tăng ca'
                break;
                case 2:
                    var result = 'Xin đổi ca làm việc';
                    break;
                case 3:
                var result = 'Bổ sung giờ làm việc ngoài văn phòng'
                break;
            }
            return this.titleDetail = result;
        },

        checkLength(item, max_length) {
            if (item.length > max_length) {
                return item.substring(0, max_length) + '...';
            } else {
                return item;
            }
        },

        showDetail(item) {
            this.dialogShowDetail = true;
            this.infoDetail = item;
            this.getFinGerPrin(item.id);
            this.query.reason_for_refusal = '';
                switch (item.category) {
                case 0:
                    var result = 'Nghỉ phép'
                break;
                case 1:
                    var result = 'Tăng ca'
                break;
                case 2:
                    var result = 'Xin đổi ca làm việc'
                break;
                case 3:
                    var result = 'Bổ sung giờ làm việc ngoài văn phòng';
                break;
            }
            return this.titleDetail = result;
        },

        getFinGerPrin(id) {
            this.$store.dispatch(SHOW_DETAIL_APPLICATION, {id
            }).then((res) => {
                this.fingerprint.fingerprint_start = res.data.fingerprint_attendance_start; 
                this.fingerprint.fingerprint_end = res.data.fingerprint_attendance_end; 
              }).catch(() => {
            });
        },

        formatDate(start,end) {
            return moment(start).format('YYYY-MM-DD') + ' - '+ moment(end).format('YYYY-MM-DD');
        },

        changeTimeCheckOut() {
            this.error.timecheckRest = false;
            let time_check_in = this.query.time_checkin;
            let time_check_out = this.query.time_checkout;
            if(time_check_out <=  time_check_in) {
                  this.error.timecheckRest = true;
                  this.$notify.error({
                    title: 'Lỗi',
                    message: 'thời gian kết thúc không hợp lệ'
                    });
                    this.query.time_checkout = '';
            }else{
                this.error.timecheckRest = false
            }
        },


        checkDate() {
            let startDate =  this.query.start_date;
            let endDate =  this.query.end_date;
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            if(!startDate || !endDate) {
                return false;
            }
            const timeDifference = endDateObj.getTime() - startDateObj.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            this.error.dateRest = false;
            if(endDate < startDate) {
                this.error.dateRest = true;
                  this.$notify.warning({
                    title: 'Lỗi',
                    message: 'Ngày kết thúc không đc nhỏ hơn ngày bắt đầu'
                    });
                    this.query.end_date = ''
            }

            if(daysDifference > 30) {
                this.error.dateRest = true;
                  this.$notify.warning({
                    title: 'Lỗi',
                    message: 'Xin nghỉ phép không quá 30 ngày'
                    });
                    this.query.end_date = ''
            }
        },

        search() {
                this.loadingTable = true;
                this.loadingSearch = true;
                this.$store.dispatch(GET_All_LIST_BOD, {
                page: this.page,
                category: this.query.category,
                status: this.query.status,
                month: this.query.month,
                reason: this.query.reason,
                keyword: this.query.keyword,
                department: this.query.department,
                date_range: this.query.date_range
            }).then((res) => {
                this.ListData = res.data;
                this.totoalData = res.pagination.total;
                this.last_page =  res.pagination.last_page;
                this.loadingTable = false;
                }).catch(() => {
            }).finally(() => {
                this.loadingSearch = false;
                this.loadingTable = false;
            });
        },
        
        getInfoUser() {
                this.$store.dispatch(USER_PROFILE, {
            }).then((res) => {
                this.infoUser = res.data;
                  this.infoUser.department = this.infoUser.use_department.filter((d) => d.user_profile_account.type == 1)[0];
                if(res.data.profile.account_type.name == 'BOD') {
                    this.is_bod =  true;
                }
                if(res.data.profile.account_type.name == 'Trưởng bộ phận') {
                    this.is_tbp =  true;
                }
                if(res.data.profile.account_type.name == 'Trưởng phòng') {
                    this.is_leader =  true;
                }
                }).catch(() => {
            });
        },

        changedilogRefuse(is_dialog) {
            if(is_dialog == 'Refuse') {
                this.dialogRefusetRequest = true;
            }
            if(is_dialog == 'Accept') {
                this.dialogConfirmRequest = true;
            }

        },
        
        actionDelete(id_applications) {
            if(id_applications){
                var id_user = id_applications;
            }else {
                var id_user = this.id_delete;

            }

            this.$store.dispatch(DELETE_USER_REQUEST, {
                id: id_user
            }).then((res) => {
                this.$notify({
                title: 'Thành công',
                message: 'Xoá yêu cầu thành công',
                type: 'success'
                });
                this.dialogShowDetail = false;
                this.dialogConfirmDeleteRequest = false;
                this.search();
            }).catch(() => {
            });
        },

        queryServer() {
            this.loading = true;
            this.$store.dispatch(INFORMATION_MECHANISM)
                .then((response) => {
                    if (response.status === 422) {
                        this.loading = false;
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                    } else {
                        this.loading = false;
                        this.data = response.data;
                    }
                })
        },

        phoneFocus(){
            this.editPhoneNumber = true; 
        },

        phoneContact() {
            let phone =  this.query.phone;
            const phoneRegex = /^\d{10}$/;
            let checkError = !(phoneRegex.test(phone));
            if(checkError) {
                this.$notify({
                    title: 'Lỗi',
                    message: 'Số điện thoại không hợp lê',
                    type: 'warning'
                });
                this.query.phone = '';
                this.error.checkphone = true;
                this.editPhoneNumber =  false;
            }
        },

        createPropose() {
            if(!(this.query.phone) && this.editPhoneNumber) {
                this.query.phone = this.infoUser.phone;
            };

           if( this.error.general_leave || this.error.dateRest || this.error.today ||
                 this.error.timecheckRest || !(this.query.reason) || this.error.checkphone 
                 || !(this.query.start_date)) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Vui lòng nhập đủ các trường!',
                        type: 'warning'
                        });
                        this.error.checkphone = false;
                    return false;
            }
            
            let start_date = moment(this.query.start_date).format('YYYY-MM-DD');
            let end_date = moment(this.query.end_date).format('YYYY-MM-DD');
            this.loadingCreateRequest = true;
            this.$store.dispatch(CREATE_APPLICATION_REQUEST, {
                current_working_change: this.query.current_working_change,
                current_working_hours:this.query.current_working_hours,
                start_shift_off: this.query.start_shift_off,
                general_leave: this.query.general_leave,
                month: this.query.month,
                start_date,
                end_date,
                category: this.query.id_type,
                reason: this.query.reason,
                phone: this.query.phone,
                time_checkin: this.query.time_checkin,
                time_checkout: this.query.time_checkout,
                date_range: this.query.date_range
            }).then((res) => {
                this.$notify({
                    title: 'Thành công',
                    message: 'Gửi yêu cầu thành công',
                    type: 'success'
                });
                this.editPhoneNumber =  false;
                this.changeCategory();
                this.dialogRequestApplications = false;
                this.query.id_type = 0;
                this.search();
            }).catch((e) => {
                this.$notify({
                  title: 'Warning',
                  message: e.data.message ?? 'Tạo yêu cầu thất bại',
                  type: 'warning'
                });
                if(this.query.start_date || this.query.end_date || this.query.reason || this.query.time_checkin || this.query.time_checkout || this.query.general_leave || this.query.current_working_change || this.query.current_working_hours) {
                this.$notify({
                        title: 'Warning',
                        message: 'Vui lòng nhập đủ các trường!',
                        type: 'warning'
                        });
                }
            }).finally(() => {
                this.loadingCreateRequest = false;
            });
        },

        checkcurrentWorkingChange() {
            let ca_hien_tai = this.query.current_working_hours;
            let ca_thay_doi = this.query.current_working_change;
            if(ca_hien_tai == ca_thay_doi) {
                this.$notify({
                    title: 'Lỗi',
                    message: 'Nhập ca thay đổi không hợp lệ',
                    type: 'warning'
                });
                this.query.current_working_change = '';
            }
        },

        time_rest(startDate, endDate) {
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);
            const timeDifference = endDateObj.getTime() - startDateObj.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            if(daysDifference == 0){
                return 1;
            }
           return daysDifference;
        },

        handleRequest(id, decided) {
            this.dialogConfirmRequest = false;
            this.dialogShowDetail = false;
            if(!decided && !this.query.reason_for_refusal) {
                this.$notify.error({
                    title: 'Lỗi',
                    message: 'Vui lòng nhập lý do từ chối',
                    offset: 100
                    });
                return false;
            }
            this.$store.dispatch(DECIDED_APPLITICATION, {
               id,
               status: decided,
               reason_for_refusal: this.query.reason_for_refusal,
                type_arise: this.query.type_arise
            }).then((res) => {
                if(decided){
                    this.$notify.success({
                    title: 'Thành công',
                    message: 'Duyệt yêu cầu thành công',
                    offset: 100
                    });
                }else{

                    this.$notify.success({
                    title: 'Thành công',
                    message: 'Từ chối yêu cầu thành công',
                    offset: 100
                    });
                }
                this.dialogConfirmRequest = false;
                this.dialogRefusetRequest = false;
                this.search();
            }).catch(() => {
                if(decided){
                    this.$notify.success({
                    title: 'Thất bại',
                    message: 'Duyệt yêu cầu thất bại',
                    offset: 100
                    });
                }else {

                    this.$notify.success({
                    title: 'Thất bại',
                    message: 'Từ chối yêu cầu thất bại',
                    offset: 100
                    });
                }
            }).finally(() => {
            });
        },

        checkToday(category) {
            let start_date = moment(this.query.start_date).format('YYYY-MM-DD');
            let today = moment().format('YYYY-MM-DD');
            if(today > start_date) {
                  this.$notify.warning({
                    title: 'Sai dữ liệu',
                    message: `"Ngày bắt đầu ${category} Không hợp lệ"`
                });
                this.query.start_date = '';
            }
        },

        getDepartments() {
            this.$store.dispatch(GET_DEPARTMENT, {
            }).then((res) => {
              this.allDepartment =  res;
            }).catch(() => {
               
            })
        },

        checkGeneralLeave() {
            this.error.general_leave =  false;
            let value_ckeck = this.query.general_leave;
            let result = value_ckeck % 0.5
            if(result || value_ckeck < 0) {
                this.$notify({
                    title: 'Thất bại',
                    message: ' Nhập số công nghỉ phép không hợp lệ',
                    type: 'warning'
                });
                this.error.general_leave =  true;
                this.query.general_leave = '';
            }

            if(value_ckeck > 30) {
                this.$notify({
                    title: 'Lỗi',
                    message: 'Tổng số công có thể nghỉ tối đa là 30',
                    type: 'warning'
                });
                this.error.general_leave =  true;
                this.query.general_leave = '';
            }
        },

        clickCallback(obj) {
            this.page = obj
            this.search()
        },
        createOverTime() {
            if (this.infoDetail.category == 1) {
                this.dialogConfirm = true;
                return ;
            }
        },
        confirmResultOverTime() {
            this.changedilogRefuse('Accept');
            this.message_type_arise = '';
            this.dialogConfirm = false;
        },
        handleClose() {
            this.dialogConfirm = false;
        },
        changeCurrentWorking(type) {
            switch (type) {
                case 1:
                    return 'Sáng - chiều';
                case 2:
                    return 'Chiều - tối';
                case 3:
                    return 'Sáng - tối';
                default:
                  return '';
            }
        }
    }
}
</script>

<style> 

    table th,
    table td {
    padding: 0.1rem;
    text-align: center;
    min-width: 120px;
    }
    
    .res-phone-contact {
        width: fit-content
    }

    #dialogShowDetail,
    #dialog-request-applications {
        font-size: 12px;
    }

    .el-date-editor.el-input{
        width: 208px;
    }

    #list-propse-application .header .el-input__inner{
            width: 300px !important;
    }

    #dialog-request-applications .el-dialog .el-input__inner{
            width: 283px!important;
    }

    #dialog-request-applications .el-dialog {
            width: 62.9% !important;
    }

    .el-date-editor .el-input{
        width: 208px;
    }

    #box-tu-choi .el-textarea__inner {
        min-height: 120px !important;
    }

    #dialogShowDetail {
        line-height: 19px;
    }

    /* Chrome, Safari, Edge, Opera */
    .hideen-arrow-type-number input::-webkit-outer-spin-button,
    .hideen-arrow-type-number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    .hideen-arrow-type-number input[type=number] {
        -moz-appearance: textfield;
    }

    .btn-refuse{
      width: 90px;
      height: 30px;
      background-color: silver;
      border-color: silver;
    }
    .btn-accept{
      width: 90px;
      height: 30px;
      background-color: #472f92;
      color: #ffff;
      border-color: #472f92;
    }

    .circle_status{
        color: rgb(5, 5, 5);
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    .selcet-type-applications{
        border-color: #B87EE7;
    }
    .btn-rest-day {
        font-size: 8px;
        padding: 5px;
        margin-top: 5px;
        opacity: 0.5;
        color: black;
    }
    .line-cao{
        line-height: 34px;
    }

    @media only screen and (max-width: 1441px){
        #dialog-request-applications .el-dialog {
            width: 67% !important;
        }
        
        #dialog-request-applications .el-dialog .el-input__inner{
            width: 225px!important;
        }

        #list-propse-application .header .el-input__inner{
            width: 100% !important;
        } 
        
        
        #dialog-request-applications #chooseType { 
            width: 284px!important;
        }
    }
   
    @media only screen and (max-width: 1025px){
        #dialog-request-applications .el-dialog {
            width: 75% !important;
        }

        #dialog-request-applications .el-dialog .el-input__inner{
            width: 100%!important;
        }

        #dialog-request-applications .el-dialog .res-input {
            display: block !important;
        }

        .res-phone-contact {
            width: 25%;
        }
    }

    @media only screen and (max-width: 821px){
        #dialogShowDetail .res-input {
            display: block !important;
            
        }
    }


    @media only screen and (max-width: 769px){
        #list-propse-application .res-input {
            display: block !important;
        }

        #dialog-request-applications .el-dialog .el-input__inner{
            width: 100% !important;
        }

        .box-request {
            display: block !important;
        }

        #dialog-request-applications .el-dialog {
            width: 69% !important;
        }

        #dialogShowDetail .el-dialog {
            width: 69% !important;
        }

        #list-propse-application .header .el-input__inner {
            width: 327px;
        }

        table th,
        table td {
        padding: 0.5rem;
        text-align: center;
        min-width: 120px;
        }
        
        .res-phone-contact {
            width: 50%;
        }
    }

    @media only screen and (max-width: 426px){
        #dialog-request-applications .el-dialog {
            width: 90% !important;
        }

        #list-propse-application .header {
            display: block !important;
        }

        #list-propse-application .type-applications {
            display: block !important;
        }

        #list-propse-application .res-head {
           width: 300px;
        }

        #dialog-request-applications .el-input__inner{
            width: 300px;
            display: block !important;
        }
        
        #dialogShowDetail .el-dialog {
            width: 95% !important;
        }

        #dialog-request-applications #chooseType { 
            width: 342px!important;
        }
    }

    @media only screen and (max-width: 431px){
        #dialog-request-applications .el-dialog {
            width: 94% !important;
        }

        .type-applications {
            display: block !important
        }

        #chooseType { 
            width: 348px!important;
        }

        /* #dialog-request-applications .phone_contact {
            width: 200px!important;
        } */

        #dialogShowDetail .el-dialog {
            width: 95% !important;
        }

        table th,
        table td {
        padding: 0.4rem;
        text-align: center;
        }
    }
  
    @media only screen and (max-width: 376px){
        #dialog-request-applications .el-dialog {
            width: 94% !important;
        }
    }
    .style-color {
        color: #454674;
    }

    .btn-css {
        background-color: #F4F5FF;
        color: #472F92;
        border-color: #B87EE7;
    }

    .btn-css:hover {
        background-color: #d0d3f4;
        color: black;
    }
    #dialogShowDetail .is-disabled input,
    #dialog-request-applications .is-disabled input  {
        color: #969595 !important;
    }

    @media only screen and (max-width: 321px){
        #dialog-request-applications #chooseType { 
            width: 251px !important;
        }

        #list-propse-application .header .el-input__inner{
            width: 284px  !important;
        } 
    }
</style>


